import React from 'react';
import './assets/css/main/main.css'
import Home from './pages/home';
import About from './pages/about';
import Team from './pages/team';
import ForEmployers from './pages/forEmployers';
import ForEmployees from './pages/ForEmployees';
import Hiring from './pages/Hiring';
import Applications from './pages/Applications';
import Contact from './pages/Contact';
import EmployeeResources from './pages/EmployeeResources';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


function AppComponent() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/employers" element={<ForEmployers />} />
          <Route path="/employees" element={<ForEmployees />} />
          <Route path="/hiring" element={<Hiring />} />
          <Route path="/application" element={<Applications />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/employee-resources" element={<EmployeeResources />} />
        </Routes>
      </Router>
    </div>
  );
}
export default AppComponent;
