import React, { useRef, useState } from 'react';
import TopNav from '../components/topNav';
import Footer from '../components/footer';
import Header from '../components/header';
import PageHeader from '../components/pageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarOfLife, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/application/application.css';

const Applications = () => {
  const iconStyles = {
    '--fa-rotate-angle': '225deg'
  };
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [submitStatus, setSubmitStatus] = useState('');
  const [state, setState] = useState('');
  const [otherState, setOtherState] = useState('');

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setState(selectedState);
    if (selectedState !== 'Other') {
      setOtherState('');
    }
  };

  const handleOtherStateChange = (e) => {
    setOtherState(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const data = new FormData(e.target);
    if (state === 'Other') {
      data.append('state', otherState);
    }
  
    try {
      const response = await fetch('https://css-backend.onrender.com/api/applications/', {
        method: 'POST',
        body: data, 
        headers: {
          'Accept': 'application/json', 
        },
      });

      if (response.ok) {
        setSubmitStatus('Application submitted successfully');
      } else {
        setSubmitStatus('Failed to submit application');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitStatus('Failed to submit application');
    }
  };

  return (
    <div className='container applications'>
      <TopNav />
      <Header />
      <PageHeader
        imageUrl="/images/applications.jpg"
        title="Grow with Us, Explore Our Job Opportunities."
      />
      <div className='contents application-page'>
        <div className='text'>
          <h1 className='title'>Don't have time for an Online Application?</h1>
          <p className='subtitle'>
            Or if you just have a few questions regarding employment opportunities,
            please fill out the form below and one of our recruiters will contact you.
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className='half-input'>
            <div className='form-group'>
              <div className='label-icon'>
                <FontAwesomeIcon className='icon' icon={faStarOfLife} />
                <label htmlFor='fname'>First Name</label>
              </div>
              <input type='text' name='first_name' id='fname' required />
            </div>
            <div className='form-group'>
              <div className='label-icon'>
                <FontAwesomeIcon className='icon' icon={faStarOfLife} />
                <label htmlFor='lname'>Last Name</label>
              </div>
              <input type='text' name='last_name' id='lname' required />
            </div>
          </div>
          <div className='half-input'>
            <div className='form-group'>
              <div className='label-icon'>
                <FontAwesomeIcon className='icon' icon={faStarOfLife} />
                <label htmlFor='zcode'>Zip code</label>
              </div>
              <input type='text' name='zipcode' id='zcode' required />
            </div>
            <div className='form-group'>
              <div className='label-icon'>
                <FontAwesomeIcon className='icon' icon={faStarOfLife} />
                <label htmlFor='state'>State</label>
              </div>
              <select name='state' id='state' required value={state} onChange={handleStateChange}>
                <option value=''>-- Select --</option>
                <option value='New York'>New York</option>
                <option value='Oklahoma'>Oklahoma</option>
                <option value='Montana'>Montana</option>
                <option value='Florida'>Florida</option>
                <option value='Texas'>Texas</option>
                <option value='Virginia'>Virginia</option>
                <option value='Washington'>Washington</option>
                <option value='Other'>Other</option>
              </select>
              {state === 'Other' && (
                <input
                  type='text'
                  name='other_state'
                  id='other_state'
                  placeholder='Type your state'
                  value={otherState}
                  onChange={handleOtherStateChange}
                  required
                />
              )}
            </div>
          </div>
          <div className='half-input'>
            {/* <div className='form-group'>
              <div className='label-icon'>
                <FontAwesomeIcon className='icon' icon={faStarOfLife} />
                <label htmlFor='discipline'>Discipline</label>
              </div>
              <select name='discipline' id='discipline' required>
                <option value=''>-- Select --</option>
                <option value='nurses'>Nurses and Providers</option>
                <option value='therapy'>Therapy</option>
                <option value='speech'>Speech</option>
                <option value='mentalHealth'>Mental Health</option>
                <option value='clinical'>Clinical</option>
                <option value='medicalAdministration'>Medical Administration</option>
              </select>
            </div> */}
            <div className='form-group'>
              <div className='label-icon'>
                <FontAwesomeIcon className='icon' icon={faStarOfLife} />
                <label htmlFor='email'>Email</label>
              </div>
              <input type='email' name='email' id='email' required />
            </div>
          </div>
          <div className='half-input'>
            <div className='form-group'>
              <div className='label-icon'>
                <FontAwesomeIcon className='icon' icon={faStarOfLife} />
                <label htmlFor='phone_number'>Phone number</label>
              </div>
              <input type='text' name='phone_number' id='phone' required />
            </div>
            <div className='form-group'>
              <div className='label-icon'>
                <FontAwesomeIcon className='icon' icon={faStarOfLife} />
                <label htmlFor='upload_cv'>Upload CV</label>
              </div>
              <div className="input-icon-wrapper">
                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} name="upload_cv" id="upload_cv" required />
                <input type="text" name="cv" id="cv" className="input-field" placeholder="" value={fileName} readOnly />
                <FontAwesomeIcon icon={faPaperclip} className="icon-file" style={iconStyles} onClick={handleIconClick} />
              </div>
            </div>
          </div>
          
          <div className='form-group'>
            <button type='submit' className='orange-btn'>Submit</button>
          </div>
        </form>

        {submitStatus && (
          <div className={submitStatus.includes('successfully') ? 'success-message' : 'error-message'}>
            {submitStatus}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Applications;
