import React, { useState, useEffect } from 'react';
import TopNav from '../components/topNav';
import Header from '../components/header';
import Footer from '../components/footer';
import CallAction from '../components/callAction';
import PageHeader from '../components/pageHeader';
import '../assets/css/employers/employers.css';

const ForEmployers = () => {
  const testimonials = [
    { text: 'Cohesive staffing has been an excellent team to work with! They are genuinely interested in making sure staffing needs are met with qualified candidates. Excellent communication, very friendly and always professional! They are most definitely our “go-to” staffing agency!', author: 'Jennifer' },
    { text: 'We currently use Cohesive Staffing Solutions to help staff our facility with RN’s, LPN’s, and CNA’s at Prague Regional Memorial Hospital. We are a critical access hospital and most of our night shifts are filled with staffing nurses from Cohesive Staffing Solutions. I have a few select nurses that have come to work at my facility through Cohesive Staffing Solutions that now only work at my facility. These nurses are dependable and attentive to my patient’s needs. The staffing nurses attend my monthly nurses meetings for education and compliance purposes. Julee Crawley, RN is the director of Cohesive Staffing Solutions is very accessible with any staffing needs, education, or any issues that arise pertaining to staffing employees. I would recommend Cohesive Staffing Solutions, over other staffing agencies we have used, to any hospitals who are in the market to fill nursing shifts.', author: 'Johnnie' },
    // { text: 'Cohesive staffing has been an excellent team to work with! They are genuinely interested in making sure staffing needs are met with qualified candidates. Excellent communication, very friendly and always professional! They are most definitely our “go-to” staffing agency!', author: 'Jennifer' },
    // { text: 'Cohesive staffing has been an excellent team to work with!', author: 'Pascaline' },
    // { text: 'We currently use Cohesive Staffing Solutions to help staff our facility.', author: 'Johnnie' },
    // { text: 'Cohesive staffing has been an excellent team to work with!', author: 'Honorine' },
    // { text: 'Cohesive staffing has been an excellent team to work with!', author: 'Pascaline' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth < 950) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(3);
      }
    };

    window.addEventListener('resize', updateSlidesToShow);
    updateSlidesToShow();

    return () => window.removeEventListener('resize', updateSlidesToShow);
  }, []);

  const showNextTestimonial = () => {
    if (currentIndex < testimonials.length - slidesToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const showPrevTestimonial = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };


  return (
    <div className="container for-employers">
      <TopNav />
      <Header />
      <PageHeader
        imageUrl="/images/employee-pgheader.jpg"
        title="We strive to provide our clients with quick and efficient solutions."
      />
      <div className="contents employers">
      <div className="services">
          <div className="text">
            <h1 className="dark-blue-title">We Serve Clients with Multiple Services</h1>
            <p>
              We understand the stress of searching for the perfect candidate, lengthy onboarding processes, and the need for staffing solutions. We are here to alleviate your stress and bridge the gap in your time of need.
            </p>
          </div>
          <div className="cards">
            <div className="card">
              <p className="yellow-background">Assist with the onboarding process</p>
            </div>
            <div className="card">
              <p className="blue-background">Find the perfect candidate</p>
            </div>
            <div className="card">
              <p className="yellow-background">Alleviate your stress</p>
            </div>
            <div className="card">
              <p className="blue-background">Provide staffing solutions</p>
            </div>
          </div>
        </div>
        <div className="onboard-process">
          <div className="process-img-text">
            <div className="process-text">
              <h1 className="dark-blue-title">Employer Onboarding Process</h1>
              <p>We strive to offer our clients a seamless experience through a simple process.</p>
              <p>
                First, we meet to discuss your unique healthcare needs. Each meeting is intentional, designed to build and sustain trusted relationships and identify your staffing needs and priorities.
              </p>
              <p>
                Next, we match our highly qualified candidates to your current needs. From our database of professionals, we pull candidates who meet your qualifications and narrow the field to find perfect candidates.
              </p>
              <p>
                We offer a top-rank service agreement to help manage your staffing needs with efficiency and confidence, never losing sight of patient care as the top priority.
              </p>
              <p>
                Our candidates undergo all essential pre-employment screenings, including criminal background checks, drug tests, reference verification, and confirmation of licenses and credentials.
              </p>
            </div>
            <img src="/images/employee-board.jpg" alt="Onboarding Process" />
          </div>
        </div>
        <div className="benefits-list">
          <h1 className="dark-blue-title">Employer Benefits</h1>
          <div className="cards">
            <div className="card"><p className="yellow-background">We are proud to maintain a high fill percentage of over 85%-90% of all our current clients requests.</p></div>
            <div className="card"><p className="blue-background">Our office offers a 24/7 call line for those critical need situations after hours.</p></div>
            <div className="card"><p className="yellow-background">Our online client portal makes it easy to request staff, approve requests, view schedules, employee health records, education, contact information, and staff availability.</p></div>
            <div className="card"><p className="blue-background">We strive to eliminate call-in's and cancellations by enforcing a proven attendance policy and point system. By enforcing these procedures developed by our seasoned staffing and nursing professionals, we have seen a drastic decrease in shift cancellations.</p></div>
            <div className="card"><p className="yellow-background">We are able to staff multiple disciplines including, but not limited to: RN, LPN, CNA, APRN, PA,DO, MD, RT/RCP, PT, PTA, OT, COTA, SLP, LPC, etc.</p></div>
            <div className="card"><p className="blue-background">We offer a top-ranking service agreement to help manage your  staffing needs with efficiency and confidence, never losing sight of patient care as the top priority.</p></div>
          </div>
        </div>
        
        <div className="testimonials">
          <h1 className="dark-blue-title">What Clients Say</h1>
          <div className="testimonial-slider">
            <div
              className="testimonial-wrapper"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {testimonials.map((testimonial, index) => (
                <div className="testimonial" key={index}>
                  <p>{testimonial.text}</p>
                  <h3>{testimonial.author}</h3>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="testimonial-navigation">
            <button
              className="prev-button"
              onClick={showPrevTestimonial}
              disabled={currentIndex === 0}
            >
              Prev
            </button>
            <button
              className="next-button"
              onClick={showNextTestimonial}
              disabled={currentIndex >= testimonials.length - slidesToShow}
            >
              Next
            </button>
          </div> */}
        </div>
        <CallAction />
      </div>
      <Footer />
    </div>
  );
};

export default ForEmployers;
