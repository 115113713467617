import React from 'react'
import '../assets/css/main/main.css';
import '../assets/css/home/home.css';
import TopNav from '../components/topNav';
import Footer from '../components/footer'
import Header from '../components/header';
import CallAction from '../components/callAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const home = () => {
    return (

        <div className='container'>
            <TopNav />
            <Header />
            <div className='home-container'>
            <section className='hero-section'>
                <div className='home-text'>
                    <h1 className='dark-blue-title'>Quality Professionals Providing Quality Care</h1>
                    <p className='text-p'>We are a staffing agency that provides area health facilities with competent and exceptional healthcare workers.
                        Our dedicated staff place healthcare workers in positions where they will not only succeed but excel.
                        At Cohesive we care for our patients and the communities we serve.
                        We strive to ensure that our organization is running at optimal ability on every level, that meets and exceeds the expectation and industry needs of temp and client services,
                        including facilitating accelerated development and long-term success.</p>
                </div>
                <img src="/images/first-section-hom.jpg" alt="home-hero-section" />
            </section>

            <section className='why-cohesive'>
                <div className='text-img'>
                    <img src="/images/why-sect-home.jpg" alt="home-why-cohesive" />
                    <div className='text'>
                        <h1 className='dark-blue-title'>Why Cohesive?</h1>
                        <p className='text-p'>
                            Our staffing service offers clients a unique and personalized service approach because we recognize that every position requires a unique solution.
                            Our dedication to customer service and improving patient care, leads to staffing only the best,
                            quality healthcare professionals for your organization. As part of our commitment to you,
                            we make sure all candidates have met the necessary pre-employment screenings required for the position.
                            We do our research upfront, so you won’t have to!
                        </p>
                    </div>
                </div>

            </section>
            <section className='our-employees'>
                <div className='employee-text'>
                    <h1 className='dark-blue-title'>Our Employees</h1>
                    <h3>To meet your staffing needs, we find candidates who are:</h3>
                    <ul className='candidate-qualities'>
                        <li>Highly qualified in their respective fields</li>
                        <li>Dedicated to patient care and satisfaction</li>
                        <li>Available to work multiple shifts </li>
                        <li>Background checked, drug screened, and credential verified</li>

                    </ul>
                </div>
                <img src="/images/employee-benefit.jpg" alt="candidate-hero-section" />
            </section>

            <section className='our-partners'>
                <h1 className='dark-blue-title'>Our Partners </h1>
                <p>Discover the companies and institutions we partner with.</p>

                <div className='partners'>
                    <div className='partner'>
                        <img src="/images/partners-img/carnegie-img.png" alt="partner1" />
                        <div className='partner-details'>
                            <h3>Carnegie Tri-County Municipal Hospital</h3>
                            <div className='partner-location'>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className='icon' />
                                <p className='text-p'>102 S. Broadway Ave <br/>
                                Carnegie, OK 73015</p>
                            </div>
                            <div className='partner-phone'>
                                <FontAwesomeIcon icon={faPhone} className='icon'/>
                                <p className='text-p'>580-654-1050</p>
                            </div>

                            <a href='https://carnegiehospital.com/'>Visit Website</a>
                        </div>

                    </div>
                    <div className='partner'>
                        <img src="/images/partners-img/seiling-img.png" alt="partner2" />
                        
                        <div className='partner-details'>
                        <h3>Seiling Municipal Hospital</h3>
                            <div className='partner-location'>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className='icon' />
                                <p className='text-p'>809 NE HWY 60 <br/> Seiling, OK 73663</p>
                            </div>
                            <div className='partner-phone'>
                                <FontAwesomeIcon icon={faPhone} className='icon'/>
                                <p className='text-p'>580-922-7361</p>
                            </div>

                            <a href='https://www.seilingmunicipalhospital.org/'>Visit Website</a>
                        </div>
                    </div>
                    <div className='partner'>
                        <img src="/images/partners-img/pawhuska-img.png" alt="partner3" />
                        
                        <div className='partner-details'>
                        <h3>Pawhuska Hospital</h3>
                            <div className='partner-location'>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className='icon' />
                                <p className='text-p'>1101 East 15th Street <br/> Pawhuska, OK 74056</p>
                            </div>
                            <div className='partner-phone'>
                                <FontAwesomeIcon icon={faPhone} className='icon'/>
                                <p className='text-p'>918-287-3232</p>
                            </div>

                            <a href='https://pawhuskahospital.com/'>Visit Website</a>
                        </div>
                    </div>
                    <div className='partner'>
                        <img src="/images/partners-img/mangum-img.png" alt="partner4" />
                        
                        <div className='partner-details'>
                        <h3>Mangum Regional Medical Center</h3>
                            <div className='partner-location'>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className='icon' />
                                <p className='text-p'>1 Wickersham Drive <br/>Mangum, OK 73554</p>
                            </div>
                            <div className='partner-phone'>
                                <FontAwesomeIcon icon={faPhone} className='icon'/>
                                <p className='text-p'>580-782-3353</p>
                            </div>

                            <a href='https://mangumregional.net/'>Visit Website</a>
                        </div>
                    </div>
                    <div className='partner'>
                        <img src="/images/partners-img/prague-img.png" alt="partner5" />
                        
                        <div className='partner-details'>
                        <h3>Prague Regional Memorial Hospital</h3>
                            <div className='partner-location'>
                                <FontAwesomeIcon icon={faMapMarkerAlt} className='icon' />
                                <p className='text-p'>1322 Klabzuba Avenue <br/>Prague, OK 74864</p>
                            </div>
                            <div className='partner-phone'>
                                <FontAwesomeIcon icon={faPhone} className='icon'/>
                                <p className='text-p'>405-567-4922</p>
                            </div>

                            <a href='https://prague.hospital/'>Visit Website</a>
                        </div>
                    </div>
                </div>
            </section>
            <CallAction />
            </div>
           
            <Footer />
        </div>
    )
}

export default home