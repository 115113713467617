import React from 'react'
import '../assets/css/call-action/call-action.css'

const callAction = () => {
  return (

    <div className=' call-to-action'>
      <div className='call-to-action-text'>
        <h1 className='dark-blue-title'>If you have any other questions or need additional help.</h1>
        <div className='line'></div>
        <div className='contact-info'>
          <p>Phone number: 405-367-4422</p>
          <p>Email: staffingteam@staffingok.com</p>
          <p>Physical location: 43155 E 45th Street, Shawnee, OK 74804 </p>
        </div>



      </div>

    </div>


  )
}

export default callAction