import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/navigation/top-nav.css';

const topNav = () => {
    return (
        <div className='container'>
            <div className='contents contacts'>
                <div className="contact-info">
                    <div className="location">
                        <a href="https://www.google.com/maps/place/816+E+Federal+St,+Shawnee,+OK+74804" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            <span>43155 E 45th Street, Shawnee, OK 74804</span>
                        </a>
                    </div>
                    <div className="email">

                        <a href="mailto:staffingteam@staffingok.com">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span>staffingteam@staffingok.com</span>
                        </a>
                    </div>
                    <div className="phone">

                        <a href="tel:4053674422">
                            <FontAwesomeIcon icon={faPhone} />
                            <span>405.367.4422</span>
                        </a>
                    </div>
                </div>
                <div className="social-media">
                    <a href="https://www.facebook.com/p/Cohesive-Staffing-Solutions-100057638200275/?_rdr">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default topNav;
