import React from 'react'
import TopNav from '../components/topNav'
import Header from '../components/header'
import Footer from '../components/footer'
import PageHeader from '../components/pageHeader'
import '../assets/css/hiring/hiring.css'
import { faMoneyCheckDollar, faDollarSign, faHouse,faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faCheckDouble, faGift,faPeopleArrows,faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Hiring = () => {
    return (
        <div className='container hiring'>
            <TopNav />
            <Header />
            <PageHeader
                imageUrl="/images/hiring-page.jpg"
                title="Explore Our Job Opportunities."
            />
            <div className='contents hiring-page '>
                <div className='expertise'>
                    <div className='expertise-img'>
                    <img src="/images/hiring-first-sect.png" alt="expertise" />
                    <div className='exertise-list'>
                        <h3 className='title'>Expertise at Every Level: Seamless Staffing Solutions</h3>
                        <div className='text-icon'>
                            <FontAwesomeIcon className='icon' icon={faCheckDouble} />
                            <div className='text'>
                                <h3>Application stage</h3>
                                <p>When we receive an application, the onboarding process begins immediately on our end.</p>
                            </div>
                        </div>
                        <div className='text-icon'>
                            <FontAwesomeIcon className='icon' icon={faCheckDouble} />
                            <div className='text'>
                                <h3>Reviewing & Screening stage</h3>
                                <p>Next, we pre-screen and interview the applicant. Our seasoned professionals in the nursing industry collaborate to determine if they are a good fit for any of our facilities.</p>
                            </div>
                        </div>
                        <div className='text-icon'>
                            <FontAwesomeIcon className='icon' icon={faCheckDouble} />
                            <div className='text'>
                                <h3>Interview & Shortlist stage</h3>
                                <p>We start with an offer letter. Then we schedule a background check and drug screen. Once we get those back clear, we then let our clients know we have a new staff member that will be ready to work soon.</p>
                            </div>
                        </div>
                        <div className='text-icon'>
                            <FontAwesomeIcon className='icon' icon={faCheckDouble} />
                            <div className='text'>
                                <h3>Onboarding stage</h3>
                                <p>During this stage, the new employee completes credentialing and education requirements.</p>
                            </div>
                        </div>
                      
                    </div>
                    </div>

                   

                </div>
                <div className='employee-benefits'>
                    <h1 className='dark-blue-title'>Employee Benefits.</h1>
                    <div className='text-section'>
                        <div className='img-text'>
                            <p>These benefits go beyond just compensation and serve as a means
                                to attract and retain skilled professionals, promote employee well-being,
                                and foster job satisfaction.</p>
                            <img src="/images/employ-bene-hiringpage.png" alt="expertise" />

                        </div>

                        <div className='benefits'>
                            <div className='benefit'>
                            <FontAwesomeIcon className='icon' icon={faDollarSign} />
                                <p>Paid Weekly</p>
                            </div>
                            <div className='benefit'>
                            <FontAwesomeIcon className='icon' icon={faHouse} />
                                <p>Insurance options</p>
                            </div>
                            <div className='benefit'>
                            <FontAwesomeIcon className='icon' icon={faGift} />
                                <p>Critical Need Bonuses</p>
                            </div>
                            <div className='benefit'>
                                <FontAwesomeIcon icon={faMoneyCheckDollar} />
                                <p>Charge Nurse Pay</p>
                            </div>
                            <div className='benefit'>
                                <FontAwesomeIcon icon={faMoneyCheckDollar} />
                                <p>Preceptor Pay</p>
                            </div>
                            <div className='benefit'>
                            <FontAwesomeIcon className='icon' icon={faUserCheck} />
                                <p>Easy Access Portal</p>
                            </div>
                            <div className='benefit'>
                                <FontAwesomeIcon icon={faMoneyCheckDollar} />
                                <p>Multiple facilities to fill shifts</p>
                            </div>
                            <div className='benefit'>
                            <FontAwesomeIcon className='icon' icon={faPeopleArrows} />
                                <p>Easy communication with staffing office</p>
                            </div>
                            <div className='benefit'>
                            <FontAwesomeIcon className='icon' icon={faCalendarAlt} />
                                <p>Staffing schedulers available 24 hours/day</p>
                            </div>
                            <div className='benefit'>
                            <FontAwesomeIcon className='icon' icon={faUserCheck} />
                                <p>Pyxis available at facilities</p>
                            </div>


                        </div>
                    </div>


                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Hiring