import React, { useState } from 'react'
import TopNav from '../components/topNav'
import Header from '../components/header'
import Footer from '../components/footer'
import PageHeader from '../components/pageHeader'
import '../assets/css/employees/employees-resources.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const EmployeeResources = () => {
    const [openQuestions, setOpenQuestions] = useState({});

    const toggleQuestion = (index) => {
        setOpenQuestions((prevOpenQuestions) => ({
            ...prevOpenQuestions,
            [index]: !prevOpenQuestions[index],
        }));
    };

    const questions = [
        {
            question: "What’s your process for a potential candidate?",
            answer: "As a candidate, your first step is to apply online (here). We'll review your qualifications and skill set. If it’s a good fit, we will contact you to arrange an interview."
        },
        {
            question: "What is your process for an interested facility?",
            answer: "Contact our office to arrange a one-on-one meeting and our director will come to your office to assess your needs and identify ways we can meet your needs."
        }
    ];

    return (




        <div className='container resources'>



            <TopNav />
            <Header />
            <PageHeader
                imageUrl="/images/employee-resources.jpg"
                title="Employee Resources."
            />
            <div className='contents resources-page'>
                <div className='work-force'>
                    <h3 className=''>Workforce Resources</h3>
                    <a href='https://ctms.contingenttalentmanagement.com/cohesivestaffing/WorkforcePortal/login.cfm'>https://ctms.contingenttalentmanagement.com/cohesivestaffing/WorkforcePortal/login.cfm</a>
                </div>
                <div className='contact-number'>
                    <h3 className=''>Contact numbers</h3>
                    <div className='contact-paylocity'>
                        <div className='employee-contacts'>
                            <div className='contact'>
                                <p>Office</p>
                                <p>405-367-4422</p>
                            </div>
                            <div className='contact'>
<p>Renda Potter</p>
{/* <p>phone number pending</p> */}
<p><b>Clinical Scheduler</b></p>

                            </div>
                          
                            <div className='contact'>
<p>Aften Palmer</p>
                                <p>405-777-4094</p>
                                <p><b>Director of Operations</b></p>
                            </div>
                            <div className='contact'>
<p>Sarah Carter</p>
                                <p>405-367-4422</p>
<p><b>Payroll Coordinator</b></p>
                            </div>
                            <div className='contact'>
<p>Kim Houston</p>
{/* <p>phone number pending</p> */}
<p><b>Clinical Scheduler</b></p>
                            </div>
                        </div>
                        <div className='contact paylocity'>
                            <p>Paylocity</p>
                            <a href='https://www.paylocity.com/'>https://www.paylocity.com/</a>
                        </div>
                    </div>



                </div>
                <div className='employee-resources'>
                    <h3>Resources for Employees</h3>
                    <p>Employees are the greatest resources a company has.</p>
                    <div className='policies'>
                        <h3>Policies</h3>
                        <ul>
                            <li>Attendance Policy</li>
                            <li>Holiday Policy</li>
                        </ul>

                    </div>
                </div>

               

                <div className='FQA'>
                    <h1 className='title'>Job Opportunity</h1>
                    <div className='questions'>
                        {questions.map((q, index) => (
                            <div className='question' key={index}>
                                <div className='question-icon' onClick={() => toggleQuestion(index)}>
                                    <h3>{q.question}</h3>
                                    <FontAwesomeIcon className='icon' icon={openQuestions[index] ? faAngleUp : faAngleDown} />
                                </div>
                                {openQuestions[index] && <p>{q.answer}</p>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default EmployeeResources