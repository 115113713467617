import React from 'react';
import TopNav from '../components/topNav';
import Header from '../components/header';
import Footer from '../components/footer';
import CallAction from '../components/callAction';
import PageHeader from '../components/pageHeader';
import '../assets/css/team/team.css';

const Team = () => {
  const teamMembers = [
    {
      name: 'Julee Crawely',
      title: 'Director of Clinical service and operation',
      imageUrl: '/images/team/Julee Crawely.png',
      description: 'Meet Julee Crawley, she is our Director of Services and operations.',
      fullDescription: 'Meet Julee Crawley, she is our Director of Clinical Services and Operations. She is responsible for ensuring that the organization is running as well as it possibly can on every level, that meets and exceeds the expectation and industry needs of temp and client services, including facilitating accelerated development and long-term success. Julee has been an RN for 23 years, with 13 of those being in management. She spent 5 years of that at a local hospital and the last 18 years in hospice where she held multiple roles including Case Manager, Patient Care Coordinator, Director of Staffing, and Administrator. Her work ethic, loyalty, and heart of compassion are her biggest assets. Her goal is to provide area facilities with competent and exceptional healthcare workers as well as place health care workers in positions where they will not only succeed but excel. Julee has been married to her husband, Ralph, for 27 years, has one daughter, Kelsee, and her husband, Nate, and expecting her first grandchild, Shaylee, in February.'
    },
    {
      name: 'Crystal Lester',
      title: 'Human Resources and Payroll Coordinator',
      imageUrl: '/images/team/Crystal.png',
      description: 'A graduate of East Central University in Ada, OK with a Bachelor of Science in Health Information Management.',
      fullDescription: 'A graduate of East Central University in Ada, OK with a Bachelor of Science in Health Information Management. She also obtained her RHIA and CCS certifications. Prior to joining our team, Crystal worked as the HR Manager at Highridge Corrosion Services for 4.5 years, assisting in many roles outside of HR. She also has 13 years’ experience in the medical field with primary responsibilities focused on medical records and documentation. Crystal enjoys working out, music, sports, hanging out with friends and family, and most importantly her two furry kids: Sam and Gus.'
    },
    {
      name: 'Darin Greene',
      title: 'Staffing Coordinator',
      imageUrl: '/images/team/Darin.png',
      description: 'Darin serves cohesive staffing solutions as staffing coordinator where he has proven his dedication to keep our client.',
      fullDescription: 'Darin serves Cohesive Staffing Solutions as a Staffing Coordinator where he has proven his dedication to keeping our client’s needs filled and our staff happy. Darin handles nursing and respiratory scheduling, provider relations, and acts as a liaison between our staff and our clients where he ensures our staff are happy with their schedules and our clients’ needs are filled in advance. Darin also shares his tech expertise and experience by always looking for new products and mending processes as we grow. Darin is inspired by Cohesive’s family-like culture and thrives in a fast paced and growing environment. In his free time, he enjoys pouring his knowledge and love for basketball into coaching his daughter’s basketball team.'
    },
    {
      name: 'Holly Lewis',
      title: 'Administrative Assistant/Staffing Coordinator',
      imageUrl: '/images/team/Holly Lewis.png',
      description: 'Holly serves Cohesive staffing solutions as an Administrative Assistant/Staffing Coordinator with twelve years of experience.',
      fullDescription: 'Holly serves Cohesive Staffing Solutions as an Administrative Assistant/Staffing Coordinator with twelve years of experience in the healthcare field and is a Licensed Practical Nurse. Her passion is to support her co-workers, and to maintain an open line of communication with healthcare professionals, whether it be through phone calls, emails, or online applications. It is important for her to be a significant contributor to the continued success of Cohesive Staffing Solutions. Holly is inspired daily by her husband of 26 years, Jeff, and their five children. In her free time, she likes to spend time with any or all of her nine grandchildren.'
    },
    {
      name: 'Salvador Villegas',
      title: 'Accountant',
      imageUrl: '/images/team/salvador.png',
      description: 'Salvador is staff accountant at Cohesive Staffing Solutions, he provides maintained accurate accounting records.',
      fullDescription: 'Salvador is a staff accountant at Cohesive Staffing Solutions. In his role, he provides maintained accurate accounting records and provides timely monthly financial statements, as well as other various accounting tasks. Salvador is a passionate individual who continues to find strategic growth initiatives to implement at Cohesive. Salvador holds a Bachelors and a Masters of Business in Accounting. He loves spending with his family and friends in his free time.'
    },
  ];

  return (
    <div className="container">
      <TopNav />
      <Header />
      <PageHeader 
        imageUrl="/images/team-page-hd.jpg"
        title="Meet Our Dedicated Team"
        description="Our staffing company is dedicated to sourcing highly skilled clinicians and providing them to healthcare organizations. We aim to ensure our clients receive high-quality care by relying on our exceptional staffing solutions."
      />
      <div className="team-container">
        <div className="contents team-members">
          {teamMembers.map((teamMember, index) => (
            <div key={index} className="team-member">
              <div className="card-overlay">
                <div className="team-member-info">
                  <h3>{teamMember.name}</h3>
                  <h4 className="title-overlay">{teamMember.title}</h4>
                  <p>{teamMember.fullDescription}</p>
                </div>
              </div>
              <div className="team-member-image">
                <img src={teamMember.imageUrl} alt="team-member" />
              </div>
              <div className="team-member-info">
                <h3>{teamMember.name}</h3>
                <h4 className="title">{teamMember.title}</h4>
                <p>{teamMember.description}</p>
              </div>
            </div>
          ))}
        </div>
        <CallAction />
      </div>
      <Footer />
    </div>
  );
};

export default Team;
