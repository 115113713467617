import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered, faXmark, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "../assets/css/navigation/header.css";
import "../assets/css/main/main.css";

const Header = () => {
  const menuIcon = useRef(null);
  const closeIcon = useRef(null);
  const nav = useRef(null);
  const [employeeDropdownOpen, setEmployeeDropdownOpen] = useState(false);

  useEffect(() => {
    const menuIconRef = menuIcon.current;
    const closeIconRef = closeIcon.current;

    function handleClick() {
      nav.current.classList.toggle("open");
    }

    menuIconRef.addEventListener("click", handleClick);
    closeIconRef.addEventListener("click", handleClick);

    return () => {
      menuIconRef.removeEventListener("click", handleClick);
      closeIconRef.removeEventListener("click", handleClick);
    };
  }, [menuIcon, closeIcon]);

  const toggleEmployeeDropdown = () => {
    setEmployeeDropdownOpen(!employeeDropdownOpen);
  };

  return (
    <header>
      <div className="container">
        <div className="contents header">
          <div className="logo">
            <NavLink to="/">
              <img src="/images/logo.svg" alt="logo" />
            </NavLink>
          </div>
          <ul className="nav" ref={nav}>
            <div>
              <FontAwesomeIcon
                icon={faXmark}
                className="close-icon"
                ref={closeIcon}
              />
            </div>
            <li>
              <NavLink exact to="/" activeClassName="active">Home</NavLink>
            </li>
            <li>
              <NavLink to="/about" activeClassName="active">About</NavLink>
            </li>
            <li>
              <NavLink to="/employers" activeClassName="active">For Employers</NavLink>
            </li>
            <li className={`dropdown ${employeeDropdownOpen ? "open" : ""}`}>
              <div className="dropdown-header" onClick={toggleEmployeeDropdown}>
                <NavLink to="/employees" activeClassName="active">For Employees</NavLink>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`dropdown-icon ${employeeDropdownOpen ? "open" : ""}`}
                />
              </div>
              {employeeDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <NavLink to="/employee-resources" activeClassName="active">Employee Resources</NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <NavLink to="/hiring" activeClassName="active">Hiring</NavLink>
            </li>
            <li>
              <NavLink to="/application" activeClassName="active">Applications</NavLink>
            </li>
            <li>
              <NavLink to="/contact" activeClassName="active">Contact</NavLink>
            </li>
            <NavLink to="/contact" className="team">
              <button className="team-btn">Get in touch</button>
            </NavLink>
          </ul>
          <div className="menu">
            <FontAwesomeIcon
              icon={faBarsStaggered}
              className="menu-bar"
              ref={menuIcon}
            />
            <NavLink to="/contact" className="team">
              <button className="team-btn">Get in touch</button>
            </NavLink>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
