import React from 'react';
import '../assets/css/main/main.css';
import '../assets/css/pageHeader/pageHeader.css';

const pageHeader = ({imageUrl, title, description}) => {
  return (
    <div className='about-us'>
        <div className="page-header">
            <img src={imageUrl} alt="{title}" />
            <div className="page-content">
            <div className='page-text'>
            <h1 className='white-title'>{title}</h1>
          <p className='page-header-p'>{description}</p>
            </div>
            
            </div>
        </div>
          
        </div>
   
  );
};

export default pageHeader;

