import React from 'react';
import TopNav from '../components/topNav';
import Footer from '../components/footer';
import Header from '../components/header';
import '../assets/css/about/about.css';
import PageHeader from '../components/pageHeader';
import CallAction from '../components/callAction';

const About = () => {
  return (
    <div className='container about-page'>
      <TopNav />
      <Header />
      <PageHeader
        imageUrl="/images/about-pageheader.jpg"
        title="About Us" 
        description="Our staffing company is dedicated to providing healthcare facilities with highly skilled clinicians. We aim to ensure our staff provides high-quality care."
      />
      
      <div className='about-container'>
        <div className='contents about-contents'>
          <p>We are committed to meeting industry needs, supporting accelerated development, and ultimately contributing to the long-term success of healthcare facilities and the patients they serve.</p>
          <h1 className='dark-blue-title'>Company Overview</h1>

          <div className='company-history'>
            <p>
              Our staffing company was established with a clear purpose in mind: to address the critical shortage of skilled clinicians, particularly in rural areas. Our primary objective is to identify and supply talented clinicians to healthcare organizations consistently. By doing so, we aim to assist our clients in maintaining a high standard of care by utilizing dependable and highly skilled professionals.
            </p>
            <p>
              As a dedicated staffing agency, our goal is twofold. Firstly, we strive to provide area facilities with competent and exceptional healthcare workers. We understand the importance of having qualified professionals who not only meet the job requirements but also excel in their roles. Secondly, we aim to place healthcare workers in positions where they can succeed and thrive. We believe in fostering an environment where our employees can provide cohesive care to the patients and communities we serve.
            </p>
            <p>
              To ensure the smooth functioning of our organization at every level, we focus on meeting and exceeding the expectations and industry needs of our clients. We provide comprehensive services that facilitate accelerated development and long-term success. Our commitment stems from the recognition of the challenges faced by healthcare organizations, and we strive to alleviate the burden by delivering top-notch staffing solutions.
            </p>
          </div>

          <div className='values'>
            <h1 className='dark-blue-title'>Our Core Values</h1>
            <div className='values-img'>
              <ul className='values-list'>
                <li className='value yellow-background'>Excellent Patient Care</li>
                <li className='value blue-background'>Excellent Client Services</li>
                <li className='value yellow-background'>Preserving Rural Healthcare</li>
                <li className='value blue-background'>Preserving Rural Healthcare Jobs</li>
              </ul>
              <img src="/images/our-values.jpg" alt="Our Core Values" />
            </div>
          </div>
        </div>

        <section className='mission'>
          <img src='/images/our-mission.jpg' alt="Our Mission" />
          <div className='text'>
            <h1>Our Mission</h1>
            <p>Our mission is to provide exceptional healthcare services to our clients by identifying and supplying talented clinicians to healthcare organizations consistently. By doing so, we aim to assist our clients in maintaining a high standard of care by utilizing dependable and highly skilled professionals.</p>
          </div>
        </section>

        <section className='partners'>
          <h1 className='dark-blue-title'>Our Trusted Partners</h1>
          <div className='partners-logo'>
            <a href="#">
              <img src="/images/logo-partners/Frame 75.png" alt="partner1" />
            </a>
            <a href="#">
              <img src="/images/logo-partners/Frame 71.png" alt="partner2" />
            </a>
            <a href="#">
              <img src="/images/logo-partners/Frame 72.png" alt="partner3" />
            </a>
            <a href="#">
              <img src="/images/logo-partners/Frame 74.png" alt="partner4" />
            </a>
            <a href="#">
              <img src="/images/logo-partners/image 28.png" alt="partner5" />
            </a>
            <a href="#">
              <img src="/images/logo-partners/Screenshot 2024-06-18 at 10.39.11 1.png" alt="partner6" />
            </a>
            <a href="#">
              <img src="/images/logo-partners/Scrubs-and-Sports-Logo-v5 2.png" alt="partner7" />
            </a>
            <a href="https://csrlimited.com/">
              <img src="/images/logo-partners/CSR logo.png" alt="partner8" />
            </a>
          </div>
        </section>

        <CallAction />
      </div>

      <Footer />
    </div>
  );
};

export default About;
