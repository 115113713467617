import React from 'react'
import TopNav from '../components/topNav'
import Header from '../components/header'
import Footer from '../components/footer'

import PageHeader from '../components/pageHeader'
import '../assets/css/employees/employees.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faSignInAlt, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';



const ForEmployees = () => {
  return (
    <div className='container for-employees'>
      <TopNav />
      <Header />
      <PageHeader
        imageUrl="/images/employee-benefit.jpg"
        title="Grow with Us, Explore Our Job Opportunities."
      />
      <div className='employees contents'>
        <p className='introduction-text'>
          Cohesive Staffing Solutions is always in pursuit of experienced healthcare professionals that are dedicated to excellent patient care and satisfaction.
        </p>
        <div className='application'>
          <div className='first-applicant'>
            <p className='title'>First time applicant?</p>
            <a href='https://recruiting.paylocity.com/recruiting/jobs/All/61a4644b-8505-4d28-bd52-31ce929f7f63/Cohesive-Staffing-Solutions' className='application-btn'>Apply Online <span>Now</span></a>
          </div>
          <div className='contact'>
            <p className='title'>Don't have time for an Online Application?</p>
            <p>
              Or if you just have a few questions regarding employment opportunities,
              please fill out the contact form and one of our recruiters will contact you.
            </p>
            <a href='/contact' className='contact-btn'>Contact Us</a>
          </div>
        </div>
        <div className='job-opportunities'>
          <h3>Are you looking for a job? Get a job recommendation according to your interests.</h3>
          <h3>
            In our experience working with rural hospitals and various healthcare facilities,
            we typically hire for the following positions.
          </h3>
          <div className='jobs'>
            <div className='job'>
              <p className='job-title'>Nurses and Providers</p>
              <ul>
                <li>Registered Nurse (RN)</li>
                <li>Licensed Practical Nurse (LPN)</li>
                <li>Certified Nursing Assistant (CNA)</li>
                <li>Nurse Practitioner (NP)</li>
                <li>Physician Assistant (PA)</li>
              </ul>
              <a href='https://recruiting.paylocity.com/recruiting/jobs/All/61a4644b-8505-4d28-bd52-31ce929f7f63/Cohesive-Staffing-Solutions'>If you are interested <span className='apply'>Apply Now</span></a>
            </div>
            <div className='job'>
              <p className='job-title'>Therapy</p>
              <ul>
                <li>Respiratory Therapist (RCP/RT)</li>
                <li>Physical Therapist (PT)</li>
                <li>Physical Therapy Assistant (PTA)</li>
                <li>Occupational Therapist (OT)</li>
                <li>Occupational Therapy Assistant (COTA)</li>
              </ul>
              <a href='https://recruiting.paylocity.com/recruiting/jobs/All/61a4644b-8505-4d28-bd52-31ce929f7f63/Cohesive-Staffing-Solutions'>If you are interested <span className='apply'>Apply Now</span></a>
            </div>
            <div className='job'>
              <p className='job-title'>Speech</p>
              <ul>
                <li>Speech Language Pathologist (SLP)</li>
                <li>Speech Language Pathology Assistant (SLPA)</li>
              </ul>
              <p className='job-title'>Mental Health</p>
              <ul>
                <li>Licensed Professional Counselor (LPC)</li>
              </ul>
              <a href='https://recruiting.paylocity.com/recruiting/jobs/All/61a4644b-8505-4d28-bd52-31ce929f7f63/Cohesive-Staffing-Solutions'>If you are interested <span className='apply'>Apply Now</span></a>
            </div>
            <div className='job'>
              <p className='job-title'>Clinical</p>
              <ul>
                <li>Medical Laboratory Technician (MLT)</li>
                <li>Phlebotomist</li>
                <li>Medical Assistant (MA)</li>
              </ul>
              <a href='https://recruiting.paylocity.com/recruiting/jobs/All/61a4644b-8505-4d28-bd52-31ce929f7f63/Cohesive-Staffing-Solutions'>If you are interested <span className='apply'>Apply Now</span></a>
            </div>
            <div className='job'>
              <p className='job-title'>Medical Administration</p>
              <ul>
                <li>Medical Biller</li>
                <li>Medical Coder</li>
                <li>Medical Records</li>
              </ul>
              <a href='https://recruiting.paylocity.com/recruiting/jobs/All/61a4644b-8505-4d28-bd52-31ce929f7f63/Cohesive-Staffing-Solutions'>If you are interested <span className='apply'>Apply Now</span></a>
            </div>
          </div>
        </div>
        <div className='notes'>
          <h3>Notes:</h3>
          <h3>
            If you do not see the licensure on the website you are looking for, or the job opening you are looking for, contact us and we can make arrangements. Though we specialize in healthcare, we are open to unique staffing solutions. This goes for both clients and employees.
          </h3>
        </div>
        <div className='employees-benefits'>
          <h1 className='dark-blue-title'>Investing in Your Future: Our Employee Benefits.</h1>
          <div className='cards'>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faCreditCard} />
              <p>Mileage Reimbursement</p>
            </div>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faDollarSign} />

              <p>Paid Weekly</p>
            </div>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faUserCheck} />
              <p>Pyxis Available at Facilities</p>
            </div>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faGift} />

              <p>Critical Need Bonuses</p>
            </div>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faCheckSquare} />

              <p>24-hour Support w/ After-Hours On-Call Assistance</p>
            </div>



            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faCreditCard} />
              <p>Preceptor Pay</p>
            </div>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faCreditCard} />
              <p>Charge Nurse Pay</p>
            </div>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faCalendarAlt} />
              <p>Flexible Scheduling,Offering Multiple Facility Choices</p>
            </div>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faSignInAlt} />
              <p>Easy Access Portal</p>
            </div>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faHouse} />
              <p>Insurance Options</p>
            </div>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faGift} />
              <p>Staff Housing</p>
            </div>
            <div className='card'>
              <FontAwesomeIcon className='icon' icon={faPeopleArrows} />
              <p>Easy Communication With Staffing Office</p>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default ForEmployees;