import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import '../assets/css/footer/footer.css';
const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div className='container'>
            <div className=' contents footer-menu'>
                <div className='footer-menu-item'>
                    <div className='follow'>
                        Follow us<a href="https://www.facebook.com/p/Cohesive-Staffing-Solutions-100057638200275/?_rdr"><FontAwesomeIcon className='icon' icon={faFacebook} /></a>
                    </div>
                    <p className='footer-p'>&copy; Copyright {currentYear}</p>
                </div>
                <div className='line'></div>
                <div className='apply-job'>
                    Are you looking for a job? <a href="https://recruiting.paylocity.com/recruiting/jobs/All/61a4644b-8505-4d28-bd52-31ce929f7f63/Cohesive-Staffing-Solutions" className='apply'>Apply Now.</a>
                </div>
            </div>
        </div>
    )
}

export default Footer
